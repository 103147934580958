import FadeInSlideShow from "components/common/FadeInSlideShow.js";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import InfoSlider from "components/common/InfoSlider";
import ScalabilityBlade from "components/common/ScalabilityBlade";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import {
  CTA_BUTTON_COPY_2,
  SECURITY_KEYWORD_HEADLINES,
} from "components/data/landing-page-data";
import { getParam } from "components/form/util/functions";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export default function SecurityB() {
  const [headline, setHeadline] = useState("");
  useEffect(() => {
    const defaultHeadline =
      "#1 Security Monitoring Platform for All Your Business Locations";
    const keyWord = getParam("utm_keyword");
    setHeadline(SECURITY_KEYWORD_HEADLINES[keyWord] ?? defaultHeadline);
  }, []);
  const renderContent = data => {
    const pageData = {
      header: {
        title: headline,
        paragraph:
          "Safeguard patrons and assets with a full suite of cloud-based security cameras, access control, audio gateways, and environmental sensors.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        theme: "light",
      },
      industryLogos: [
        {
          img: data.logo1,
          width: "118px",
          alt: "Amazon",
        },
        {
          img: data.logo2,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo3,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo4,
          width: "115px",
          alt: "Stryker",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Contruction",
        },
      ],
      infoSlides: [
        {
          tabTitle: "Smart Cameras",
          title: "Protect What Matters Most with Powerful Camera Solutions",
          description:
            "Rhombus’ suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a single pane of glass.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Access Control",
          title: "Unify Building Management Like Never Before",
          description:
            "Pair seamlessly with the Rhombus Platform for all-in-one security at scale. Manage all aspects of your spaces from a single pane of glass to streamline operations, eliminate complexity, and improve safety.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Environmental Monitoring",
          title: "Create Healthier Spaces and Manage Climate-Controlled Areas",
          description:
            "Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Proactive Alerts",
          title:
            "Stay in the know with real-time alerts for faces, vehicles, and more",
          description:
            "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
          image: data.infoSlide4,
        },
        {
          tabTitle: "Seamless Deployment",
          title: "Register plug-and-play devices in as little as 5 minutes",
          description:
            "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates. ",
          image: data.infoSlide5,
        },
      ],
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Safeguarding Visitors + Assets | Rhombus™ Security</title>
          <meta
            name="description"
            content="Proactively prepare for emergencies and respond swiftly when threats arise."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={pageData.header}
          theme={pageData.header.theme}
          formTitle={CTA_BUTTON_COPY_2}
          buttonText={CTA_BUTTON_COPY_2}
          width="953px"
          height="600px"
          useCustomCase
        />
        <IndustryFeaturedUpdated
          title="Trusted by Industry Leaders"
          logos={pageData.industryLogos}
          color="var(--white)"
        />
        <SectionContainer style={{ paddingTop: 0 }}>
          <SectionInner>
            <SectionCol>
              <FadeInSlideShow
                images={[data.imageFader1, data.imageFader2, data.imageFader3]}
              />
            </SectionCol>
            <FlexEndColumn>
              <TextContainer width="550px">
                <FancyTitleMed style={{ textAlign: "left" }}>
                  Proactively Identify Threats and Reduce Incident Response Time
                </FancyTitleMed>
                <MainParagraph>
                  Crystal-clear security footage meets best-in-class AI and
                  cloud technologies. Identify and address unauthorized access
                  and unsafe behavior across your sites from anywhere.
                </MainParagraph>
              </TextContainer>
            </FlexEndColumn>
          </SectionInner>
        </SectionContainer>
        <InfoSlider
          data={pageData.infoSlides}
          title="An All-in-One Platform"
          button
          color="var(--nuetral-100)"
          buttonCopy={CTA_BUTTON_COPY_2}
        />
        <ScalabilityBlade />
        <TrustedPartnerSection buttonCopy={CTA_BUTTON_COPY_2} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/trust/img/trust-hero-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: { eq: "components/trust/img/trust-hero-1-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/landing-pages/search/img/smart-cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: {
          eq: "components/landing-pages/search/img/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      imageFader1: file(
        relativePath: {
          eq: "components/landing-pages/img/r100-outdoor-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imageFader2: file(
        relativePath: {
          eq: "components/landing-pages/img/r100-outdoor-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imageFader3: file(
        relativePath: {
          eq: "components/landing-pages/img/r100-outdoor-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
